<template>
  <component
    v-if="authors"
    :is="hasMultipleAuthors ? 'span' : 'nuxt-link'"
    :to="to"
  >
    {{ title }}
  </component>
</template>

<script>
  import { getTitle } from '@/helpers';

  export default {
    name: 'Author',

    props: {
      authors: {
        type: Array,
        default: null
      }
    },

    computed: {
      hasMultipleAuthors() {
        return this.authors.length > 1;
      },
      singleAuthor() {
        // return this.authors[0].author;
        return this.authors[0];
      },
      to() {
        return this.hasMultipleAuthors ? null : `/beauty-edit/author/${this.singleAuthor._meta.uid}`;
      },
      title() {
        return this.hasMultipleAuthors ? 'The Gee Women' : getTitle(this.singleAuthor.name);
      }
    }
  }
</script>
