<template>
  <component
    :is="to || prismicTo || href || prismicHref ? (isInternal ? 'nuxt-link' : 'a') : (isFakeButton ? 'div' : 'button')"
    :to="to ? to : prismicTo"
    :target="!isInternal ? '_blank' : null"
    :role="isFakeButton ? 'button' : null"
    :tabindex="isFakeButton ? 0 : null"
    @click="$emit('click')"
    @mouseover="$emit('mouseover')"
    class="arrow-link"
    :class="[ 'arrow-link--' + size ]"
  >
    <span>
      {{ text }}
      <SvgIcon name="chevron-right" />
    </span>
  </component>
</template>

<script>
  import prismicLinkMixin from '@/mixins/prismicLinkMixin';

  export default {
    name: 'ArrowLink',

    mixins: [ prismicLinkMixin ],

    components: {
      SvgIcon: () => import('@/components/global/SvgIcon')
    },

    props: {
      size: {
        type: String,
        default: 'large'
      },
      isFakeButton: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      if(!this.isInternal && (this.href || this.prismicHref)) {
        this.$el.setAttribute('href', this.href ? this.href : this.prismicHref);
      }
    }
  }
</script>
