<template>
  <div class="seal">
    <SvgIcon name="seal-icon" title="Gee Tried & Tested" />
  </div>
</template>

<script>
import SvgIcon from "@/components/global/SvgIcon";

export default {
  name: "Seal",

  components: {
    SvgIcon
  }
};
</script>
