<template>
  <div v-if="article" class="article-card" :class="[type ? 'article-card--' + type : '', { 'is-equal-height' : isEqualHeight }]">
    <div class="article-card__image">
      <PrismicLazyImage
        :to="to"
        :image="article.featured_image"
        :width="imageWidth"
        :height="imageHeight"
      />
      <SvgIcon v-if="isVideo" name="play" />
      <h3 v-if="isHeader">{{ title }}</h3>
      <nuxt-link v-if="showCategory && category && !isHeader && type !== 'split'" :to="categoryTo" class="category"><span>{{ categoryTitle }}</span></nuxt-link>
    </div>
    <div v-if="!isHeader" class="article-card__content" :class="{ 'has-author' : article.author }">
      <div>
        <nuxt-link v-if="showCategory && category" :to="categoryTo" class="category"><span>{{ categoryTitle }}</span></nuxt-link>
        <nuxt-link :to="to" class="title"><h3>{{ title }}</h3></nuxt-link>
        <p v-if="excerpt && type == 'large'">{{ excerpt }}</p>
      </div>
      <div v-if="showAuthor && article.author" class="author">
        By <Author :authors="[article.author]" />
      </div>
    </div>
  </div>
</template>

<script>
  import { getTitle, truncate } from '@/helpers';

  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import SvgIcon from '@/components/global/SvgIcon';
  import Author from '@/components/global/Author';

  export default {
    name: 'ArticleCard',

    components: {
      PrismicLazyImage,
      SvgIcon,
      Author
    },

    props: {
      type: {
        type: String,
        default: 'medium'
      },
      article: {
        type: Object,
        default: null
      },
      showCategory: {
        type: Boolean,
        default: true
      },
      showAuthor: {
        type: Boolean,
        default: true
      },
      imageWidth: {
        type: [Number, String],
        default: null
      },
      imageHeight: {
        type: [Number, String],
        default: null
      },
      isEqualHeight: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      isHeader() {
        return this.type == 'header';
      },
      to() {
        return `/beauty-edit/${this.article._meta.uid}`;
      },
      title() {
        return getTitle(this.article.title);
      },
      category() {
        return this.showCategory ? this.article.category : null;
      },
      categoryTo() {
        return this.showCategory ? `/beauty-edit/category/${this.category._meta.uid}` : null;
      },
      categoryTitle() {
        return this.showCategory ? getTitle(this.category.title) : null;
      },
      isVideo() {
        return this.article.is_video_article;
      },
      excerpt() {
        if(this.article.content) {
          return truncate(this.article.content[0].text, 100);
        }
      }
    }
  }
</script>
