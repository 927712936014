<template>
  <div class="stars">
    <SvgIcon v-for="star in numFilledStars" :key="'filled' + star" name="star-filled" />
    <SvgIcon v-if="hasHalfStar" name="star-half" />
    <SvgIcon v-for="star in numEmptyStars" :key="'empty' + star" name="star-empty" />
    <span class="accessible">{{ numStars }} out of 5 stars</span>
  </div>
</template>

<script>
  export default {
    name: 'Stars',

    components: {
      SvgIcon: () => import('@/components/global/SvgIcon')
    },

    props: {
      numStars: {
        type: Number,
        default: 0
      }
    },

    computed: {
      roundedRating() {
        const rounded = this.round(this.numStars, 0.5);

        return rounded < 5 ? rounded : 5;
      },
      numFilledStars() {
        return Math.floor(this.roundedRating);
      },
      hasHalfStar() {
        return this.roundedRating !== this.numFilledStars;
      },
      numEmptyStars() {
        return Math.floor(5 - this.roundedRating);
      }
    },

    methods: {
      round(value, step) {
        step || (step = 1.0);
        const inv = 1.0 / step;
        return Math.round(value * inv) / inv;
      }
    }
  }
</script>
