<template>
  <span
    class="tooltip"
    :class="[
      { 'is-open': computedIsOpen },
      { 'is-click': isClick },
      { 'is-wide': threeColumns },
      { 'is-arrow-dropdown': isArrowDropdown },
      direction ? 'tooltip--' + direction : '',
      align ? 'tooltip--align-' + align : '',
    ]">
    <button
      class="tooltip__trigger"
      @mouseover="openTooltip"
      @click="openTooltip"
      @keyup.enter="openTooltip">
      <slot name="trigger"></slot>
      <SvgIcon v-if="isArrowDropdown" name="chevron-select" />
    </button>

    <transition-group>
      <div v-if="computedIsOpen" key="content" class="tooltip__content">
        <div class="tooltip__content__inner">
          <slot name="content"></slot>
        </div>
      </div>

      <div
        v-if="computedIsOpen"
        key="overlay"
        class="tooltip__overlay"
        @mouseover="closeTooltip"
        @click="closeTooltip"></div>
    </transition-group>
  </span>
</template>

<script>
  import SvgIcon from '@/components/global/SvgIcon'

  export default {
    name: 'Tooltip',

    components: {
      SvgIcon,
    },

    props: {
      direction: {
        type: String,
        default: 'bottom',
      },
      align: {
        type: String,
        default: 'center',
      },
      isClick: {
        type: Boolean,
        default: false,
      },
      isArrowDropdown: {
        type: Boolean,
        default: false,
      },
      threeColumns: {
        type: Boolean,
        default: false,
      },
      hasMulti: {
        type: Boolean,
        default: false
      },
      index: {
        type: Number,
        default: 0
      },
      multiCurrentIndex: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        isOpen: false,
        isTouch: process.client
          ? 'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0
          : false,
      }
    },

    computed: {
      computedIsOpen() {
        if(this.hasMulti) {
          return this.isOpen && (this.index == this.multiCurrentIndex);
        } else {
          return this.isOpen;
        }
      }
    },

    methods: {
      openTooltip($event) {
        if (this.isClick && $event.type == 'mouseover') {
          return
        }

        this.$emit('open');

        this.isOpen = true

        if(this.hasMulti) {
          this.$emit('opened', this.index)
        }
      },
      closeTooltip($event) {
        if (this.isClick && $event.type == 'mouseover') {
          return
        }

        this.isOpen = false
      },
    },

    watch: {
      $route: {
        handler(to, from) {
          this.isOpen = false
        },
        immediate: true,
      },
    },

    mounted() {
      document.addEventListener('keyup', evt => {
        if (evt.keyCode === 27) {
          this.isOpen = false
        }
      })
    },
  }
</script>
