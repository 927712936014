var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"large"},[_c('li',[_c('nuxt-link',{class:{ 'is-active': _vm.$route.name === 'profile' },attrs:{"to":"/account"}},[_vm._v("\n      My Profile\n    ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:{
        'is-active':
          _vm.$route.name === 'order-history' || _vm.$route.name === 'order-detail',
      },attrs:{"to":"/account/orders"}},[_vm._v("\n      Order History\n    ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:{
        'is-active':
          _vm.$route.name === 'addresses' ||
          _vm.$route.name === 'edit-address' ||
          _vm.$route.name === 'new-address',
      },attrs:{"to":"/account/addresses"}},[_vm._v("\n      Addresses\n    ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:{ 'is-active': _vm.$route.name === 'beauty-marks-account' },attrs:{"to":"/account/beauty-marks"}},[_vm._v("\n      Beauty Marks\n    ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:{
        'is-active':
          _vm.$route.name === 'subscriptions' ||
          _vm.$route.name === 'subscription-details',
      },attrs:{"to":"/account/subscriptions"}},[_vm._v("\n      Subscriptions\n    ")])],1),_vm._v(" "),_c('li',[_c('button',{on:{"click":_vm.logOut}},[_vm._v("Log Out")])])])}
var staticRenderFns = []

export { render, staticRenderFns }