<template>
  <button @click="startChat" class="chat">
    <svg
      width="47px"
      height="46px"
      viewBox="0 0 47 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="chat__icon"
    >
      <title>Chat Now</title>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1263.000000, -1592.000000)">
          <g transform="translate(1259.661603, 1592.000000)">
            <circle class="bg" cx="27.3383975" cy="23" r="23"></circle>
            <polygon
              class="bg"
              transform="translate(10.930500, 33.930500) rotate(78.000000) translate(-10.930500, -33.930500) "
              points="19.861 25 2 31.698 19.861 42.861"
            ></polygon>
            <g>
              <circle
                fill="#FFFFFF"
                cx="17.8383975"
                cy="22.5"
                r="2.5"
                transform="translate(0 0)"
              ></circle>
              <animateTransform
                attributeName="transform"
                additive="replace"
                type="translate"
                calcMode="linear"
                begin="0"
                dur="5"
                repeatCount="indefinite"
                keyTimes="0;0.95;0.975;1"
                values="0,0;0,0;0,-3;0,0"
              />
            </g>
            <g>
              <circle
                fill="#FFFFFF"
                cx="26.8383975"
                cy="22.5"
                r="2.5"
                transform="translate(0 0)"
              ></circle>
              <animateTransform
                attributeName="transform"
                additive="replace"
                type="translate"
                calcMode="linear"
                begin="0.15"
                dur="5"
                repeatCount="indefinite"
                keyTimes="0;0.95;0.975;1"
                values="0,0;0,0;0,-3;0,0"
              />
            </g>
            <g>
              <circle
                fill="#FFFFFF"
                cx="36.8383975"
                cy="22.5"
                r="2.5"
                transform="translate(0 0)"
              ></circle>
              <animateTransform
                attributeName="transform"
                additive="replace"
                type="translate"
                calcMode="linear"
                begin="0.3"
                dur="5"
                repeatCount="indefinite"
                keyTimes="0;0.95;0.975;1"
                values="0,0;0,0;0,-3;0,0"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <span v-if="showText && text" class="arrow-link arrow-link--small">
      {{ text }} <SvgIcon name="chevron-right" />
    </span>
  </button>
</template>

<script>
import { mapState } from "vuex";

import SvgIcon from '@/components/global/SvgIcon'

export default {
  name: "Chat",

  components: {
    SvgIcon
  },

  props: {
    showText: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      text: state => state.globalData.chat_bubble_text
    })
  },

  methods: {
    startChat() {
      this.$liveChat.update({ current_page: window.location.href });

      this.$liveChat.show();
    }
  }
};
</script>
