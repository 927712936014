<template>
  <svg :class="name" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="'#icon-' + name" xmlns:xlink="http://www.w3.org/1999/xlink"/>
  </svg>
</template>

<script>
  export default {
    name: 'SvgIcon',

    props: {
      name: {
        type: String,
        required: true
      },
      title: {
        type: String,
        default: null
      }
    }
  }
</script>
