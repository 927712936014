<template>
  <div v-if="type && isReady" class="callout column small-12 xlarge-6">
    <div class="row">
      <div class="callout__left column small-12 medium-7">
        <div class="row">
          <div class="column small-7 medium-12">
            <span class="h5">{{ title }}</span>
            <h2 v-if="subtitle" class="h3"><prismic-rich-text :field="subtitle" /></h2>
            <prismic-rich-text v-if="description && description[0] && description[0].text" :field="description" />
            <p v-else-if="description">{{ description }}</p>
          </div>
          <div v-if="image" class="column small-5 medium-12">
            <PrismicLazyImage
              v-if="image"
              :image="image"
              width="380"
              height="380"
            />
          </div>
        </div>
        <Button
          :link="link"
          :text="linkText"
          size="small"
          :isFullWidth="true"
          @click="handleClick"
        />
      </div>
      <div class="callout__right column small-12 medium-5">
        <PrismicLazyImage
          v-if="image"
          :image="image"
          width="380"
          height="380"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import calloutMixin from '@/mixins/calloutMixin';

  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import Button from '@/components/global/Button';
  import LazyImage from '@/components/global/LazyImage';

  export default {
    name: 'Callout',

    mixins: [ calloutMixin ],

    components: {
      PrismicLazyImage,
      Button,
      LazyImage
    },

    props: {
      type: {
        type: String,
        default: null
      },
      collection: {
        type: Object,
        default: null
      }
    }
  }
</script>
