<template>
  <footer class="footer">
    <div class="footer__top row">
      <div class="footer__logo column small-6 large-3 xlarge-2">
        <component
          :is="isHomepage ? 'a' : 'nuxt-link'"
          href="#__nuxt"
          v-smooth-scroll
          to="/">
          <SvgIcon name="g" :title="$config.siteName" />
        </component>

        <PrismicMenu :menu="global.legal_menu" />
      </div>

      <div class="footer__list-1 column small-6 large-3 xlarge-2">
        <h4 v-if="global.primary_footer_menu_heading">
          {{ global.primary_footer_menu_heading }}
        </h4>

        <PrismicMenu :menu="global.primary_footer_menu" />
      </div>

      <div class="footer__list-2 column small-6 large-3 xlarge-2">
        <h4 v-if="global.secondary_footer_menu_heading">
          {{ global.secondary_footer_menu_heading }}
        </h4>

        <PrismicMenu :menu="global.secondary_footer_menu" />
      </div>

      <div class="footer__list-3 column small-6 large-3 xlarge-2">
        <h4>Follow Us</h4>

        <PrismicMenu :menu="global.social_menu" />
      </div>

      <div class="footer__form column small-12 xlarge-4">
        <h5 v-if="global.newsletter_heading">
          <prismic-rich-text :field="global.newsletter_heading" />
        </h5>

        <p v-if="global.newsletter_description">
          {{ global.newsletter_description }}
        </p>

        <form
          class="form"
          :class="{ 'has-errors': $v.$anyError }"
          @submit.prevent="handleSubmit">
          <!-- method="POST"
        :action="`https://manage.kmail-lists.com/subscriptions/subscribe?a=${klaviyoApiKey}&g=${list_id}`" -->
          <!-- <input type="hidden" name="g" :value="list_id" /> -->
          <div class="form__row">
            <div class="form__field" :class="{ 'has-error': $v.email.$error }">
              <label class="accessible" for="email">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                v-model.trim="email"
                placeholder="Your Email" />

              <button type="submit"><SvgIcon name="arrow-right" /></button>

              <span class="error" v-if="$v.email.$error && !$v.email.required">
                This field is required
              </span>
              <span class="error" v-if="$v.email.$error && !$v.email.email">
                Please enter a valid email address
              </span>

              <FormFeedback
                :darkMode="true"
                :showFeedback="showFeedback"
                :submitState="submitState"
                :submitMessage="submitMessage" />
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="footer__bottom row">
      <div class="footer__copyright column small-6 large-3 xlarge-2">
        © {{ year }} {{ $config.siteName }}.
      </div>

      <div class="footer__list-4 column small-6 large-6 xlarge-4">
        <PrismicMenu :menu="global.legal_menu" />
      </div>

      <div class="footer__credit column small-6 large-3 xlarge-6">
        <a href="https://madebyarticle.com/" target="_blank">
          <SvgIcon name="madebyarticle" title="Made By Article" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
  import axios from 'axios'
  import Cookie from 'js-cookie'

  import { mapState } from 'vuex'

  import { required, email } from 'vuelidate/lib/validators'

  import formMixin from '@/mixins/formMixin'

  import PrismicMenu from '@/components/prismic/PrismicMenu'
  import SvgIcon from '@/components/global/SvgIcon'
  import FormFeedback from '@/components/global/FormFeedback'

  export default {
    name: 'Footer',

    mixins: [formMixin],

    components: {
      PrismicMenu,
      SvgIcon,
      FormFeedback,
    },

    data() {
      return {
        email: null,
        list_id: process.env.NUXT_ENV_KLAVIYO_LIST_ID,
        klaviyoApiKey: process.env.NUXT_ENV_KLAVIYO_PUBLIC_KEY,
      }
    },

    validations: {
      email: {
        required,
        email,
      },
    },

    computed: {
      ...mapState({
        global: state => state.globalData,
        locale: state => state.locale,
      }),

      year() {
        return new Date().getFullYear()
      },

      isHomepage() {
        return this.$route.name == 'home'
      },
    },

    methods: {
      async handleSubmit() {
        this.$v.$touch()
        this.showFeedback = false
        this.isSubmitting = true

        if (this.$v.$invalid) {
          this.handleFormFeedback('error')
          return
        }

        const { message } = await this.$klaviyo.addToDefaultList(
          this.locale,
          this.email
        )

        Cookie.set('gee_article_gate', false)

        this.handleFormFeedback('success', message)
      },
    },
  }
</script>
