<template>
  <Modal type="contact">
    <template v-slot:title>
      <h2>{{ title }}</h2>
    </template>
    <template v-slot:body>
      <a v-if="computedStudio && computedStudio.phone_number" :href="`tel:${computedStudio.phone_number}`">
        <SvgIcon name="phone" />
        {{ computedStudio.phone_number }}
      </a>
      <a v-if="computedStudio && computedStudio.email" :href="`mailto:${computedStudio.email}${computedSubject ? '?subject=' + computedSubject : ''}`" class="arrow-link">
        <div>
          <SvgIcon name="mail" />
          {{ computedStudio.email }}
        </div>
        <SvgIcon name="chevron-right" />
      </a>
      <button class="arrow-link" @click="startChat">
        <div>
          <SvgIcon name="chat-bubble" />
          Chat Now
        </div>
        <SvgIcon name="chevron-right" />
      </button>
    </template>
  </Modal>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';

  export default {
    name: 'ContactModal',

    components: {
      Modal: () => import('@/components/global/Modal'),
      SvgIcon: () => import('@/components/global/SvgIcon')
    },

    props: {
      type: {
        type: String,
        default: 'contact'
      }
    },

    computed: {
      ...mapGetters([
        'localStudio'
      ]),
      ...mapState({
        subject: state => state.modal.contact.subject,
        studio: state => state.modal.contact.booking.studio,
        service: state => state.modal.contact.booking.service
      }),
      isBooking() {
        return this.studio && this.service ? true : false;
      },
      title() {
        if(this.isBooking) {
          return 'Book An Appointment';
        }

        return 'Contact Us';
      },
      computedStudio() {
        if(this.isBooking) {
          return this.studio;
        }

        return this.localStudio;
      },
      computedSubject() {
        if(this.isBooking) {
          return `Appointment Request: ${this.service} in ${this.$prismic.asText(this.studio.title)}`;
        }

        return this.subject;
      }
    },

    methods: {
      ...mapActions([
        'closeModal'
      ]),
      startChat() {
        this.$liveChat.update({ current_page: window.location.href });

        this.$liveChat.show();

        this.closeModal();
      }
    }
  }
</script>
