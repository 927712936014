<template>
  <section class="follow">
    <div class="row">
      <div class="follow__left column small-12 xlarge-4">
        <div class="stacked-images">
          <div v-if="posts[0]" class="front-image">
            <LazyImage
              :href="posts[0].link"
              :src="posts[0].image"
              width="400"
              height="400"
              :alt="truncate(posts[0].caption, 100)"
              class="cover"
            />
          </div>

          <div v-if="posts[1]" class="back-image">
            <LazyImage
              :href="posts[1].link"
              :src="posts[1].image"
              width="400"
              height="400"
              :alt="truncate(posts[1].caption, 100)"
              class="cover"
            />
          </div>
        </div>

        <div class="stacked-images stacked-images--reverse">
          <div v-if="posts[2]" class="back-image">
            <LazyImage
              :href="posts[2].link"
              :src="posts[2].image"
              width="425"
              height="425"
              :alt="truncate(posts[2].caption, 100)"
              class="cover"
            />
          </div>

          <div v-if="posts.length" class="front-image transparent">
            <PrismicLazyImage
              :image="additionalImage"
              width="350"
              height="350"
            />
          </div>
        </div>
        <SvgIcon name="instagram" title="Instagram" />
      </div>

      <div class="follow__center column small-12 xlarge-4">
        <h2 v-if="heading"><prismic-rich-text :field="heading" /></h2>
        <p v-if="description">{{ description }}</p>
        <Button
          :href="`https://www.instagram.com/${handle}`"
          size="large"
          :text="buttonText ? buttonText : 'Follow Us'"
          :isInstagram="true"
        />
      </div>

      <div class="follow__right column small-12 xlarge-4">
        <div class="stacked-images stacked-images--reverse">
          <div v-if="posts[2]" class="back-image">
            <LazyImage
              :href="posts[2].link"
              :src="posts[2].image"
              width="425"
              height="425"
              :alt="truncate(posts[2].caption, 100)"
              class="cover"
            />
          </div>
          <div v-if="posts.length" class="front-image transparent">
            <PrismicLazyImage
              :image="additionalImage"
              width="350"
              height="350"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'

  import { truncate } from '@/helpers'

  import LazyImage from '@/components/global/LazyImage'
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage'
  import SvgIcon from '@/components/global/SvgIcon'
  import Button from '@/components/global/Button'

  export default {
    name: 'Follow',

    data() {
      return {
        posts: [],
      }
    },

    components: {
      LazyImage,
      PrismicLazyImage,
      SvgIcon,
      Button,
    },

    computed: {
      ...mapState({
        handle: state => state.globalData.instagram_handle,
        heading: state => state.globalData.instagram_heading,
        description: state => state.globalData.instagram_description,
        buttonText: state => state.globalData.instagram_button_text,
        additionalImage: state => state.globalData.instagram_additional_image,
      }),
    },

    methods: {
      truncate,
    },

    async created() {
      try {
        const { data } = await axios.get(
          `https://madebyarticle.com/insta-js.php?username=${this.handle}`
        )

        if (!data.length) {
          console.log('There was an error bringing in Instagram posts')
          return
        }

        this.posts = data
      } catch (err) {
        console.log(err)
      }
    },
  }
</script>
