<template>
  <button
    class="button"
    :class="[
      isShowLess ? 'button--show-less' : 'button--load-more',
      size ? 'button--' + size : '',
    ]"
    @click="$emit('click')"
  >
    {{ isShowLess ? 'Show Less' : 'Load More' }}
    <SvgIcon name="chevron-select" />
  </button>
</template>

<script>
  import SvgIcon from '@/components/global/SvgIcon'

  export default {
    name: 'LoadMoreButton',

    components: {
      SvgIcon,
    },

    props: {
      isShowLess: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: null,
      },
    },
  }
</script>
