<template>
  <client-only>
  <transition>
    <div v-show="isOpen" class="cookie-bar" :class="{ 'is-open' : isOpen }">
      <prismic-rich-text v-if="text" :field="text" />
      <button class="cookie-bar__close" @click="closeCookieBar">
        <SvgIcon name="close" title="Close" />
      </button>
    </div>
  </transition>
  </client-only>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'CookieBar',

    components: {
      SvgIcon
    },

    computed: {
      ...mapState({
        isOpen: state => state.user.isCookieBarOpen,
        text: state => state.globalData.cookie_bar_text
      })
    },

    methods: {
      ...mapActions([
        'closeCookieBar'
      ])
    }
  }
</script>
