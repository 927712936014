<template>
  <div class="more-less-text" :class="{ 'is-open' : isOpen }">
    <slot name="text"></slot>
    <slide-up-down :active="isOpen">
      <slot name="moreText"></slot>
    </slide-up-down>
    <button v-if="this.$slots.moreText" class="button" @click="isOpen = !isOpen">{{ isOpen ? 'Less -' : 'More +' }}</button>
  </div>
</template>

<script>
  import SlideUpDown from 'vue-slide-up-down';

  export default {
    name: 'MoreLessText',

    components: {
      SlideUpDown
    },

    data() {
      return {
        isOpen: false
      }
    }
  }
</script>
