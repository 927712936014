<template>
  <button class="copy-link" @click="copyLink(link)">
    <template v-if="!isIcon">
      Copy Link <span>+</span>
    </template>
    <template v-else>
      <SvgIcon name="copy" title="Copy Link" />
    </template>
    <input type="hidden" id="link-input" class="accessible" :value="link">
    <transition>
      <span v-if="isCopied" class="feedback">Link Copied</span>
    </transition>
  </button>
</template>

<script>
  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'CopyLink',

    components: {
      SvgIcon
    },

    data() {
      return {
        isCopied: false
      }
    },

    props: {
      link: {
        type: String,
        default: null
      },
      isIcon: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      copyLink(link) {
        let linkInput = document.querySelector('#link-input');
        linkInput.setAttribute('type', 'text');
        linkInput.select();

        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
          var el = linkInput.get(0);
          var editable = el.contentEditable;
          var readOnly = el.readOnly;
          el.contentEditable = true;
          el.readOnly = false;
          var range = document.createRange();
          range.selectNodeContents(el);
          var sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
          el.setSelectionRange(0, 999999);
          el.contentEditable = editable;
          el.readOnly = readOnly;
        } else {
          linkInput.setAttribute('type', 'text');
          linkInput.select();
        }

        document.execCommand('copy');
        linkInput.setAttribute('type', 'hidden');
        window.getSelection().removeAllRanges();

        this.isCopied = true;

        setTimeout(() => {
          this.isCopied = false;
        }, 2500);
      }
    }
  }
</script>
