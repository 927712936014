<template>
  <div v-if="video" class="video-wrapper">
    <prismic-embed v-if="video.provider_name" :field="video" />
    <video
      v-else
      :autoplay="isAutoplay"
      :muted="isAutoplay"
      :loop="isAutoplay"
      :playsinline="isAutoplay"
      :controls="!isAutoplay"
      :poster="placeholder && placeholder.url ? placeholder.url : placeholder"
    >
      <source :src="video.url">
    </video>
  </div>
</template>

<script>
  export default {
    name: 'Video',

    props: {
      video: {
        type: Object,
        default: null
      },
      placeholder: {
        type: Object,
        default: null
      },
      isAutoplay: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
