<template>
  <ul class="large">
    <li>
      <nuxt-link
        to="/account"
        :class="{ 'is-active': $route.name === 'profile' }">
        My Profile
      </nuxt-link>
    </li>

    <li>
      <nuxt-link
        to="/account/orders"
        :class="{
          'is-active':
            $route.name === 'order-history' || $route.name === 'order-detail',
        }">
        Order History
      </nuxt-link>
    </li>

    <li>
      <nuxt-link
        to="/account/addresses"
        :class="{
          'is-active':
            $route.name === 'addresses' ||
            $route.name === 'edit-address' ||
            $route.name === 'new-address',
        }">
        Addresses
      </nuxt-link>
    </li>

    <li>
      <nuxt-link
        to="/account/beauty-marks"
        :class="{ 'is-active': $route.name === 'beauty-marks-account' }">
        Beauty Marks
      </nuxt-link>
    </li>

    <li>
      <nuxt-link
        to="/account/subscriptions"
        :class="{
          'is-active':
            $route.name === 'subscriptions' ||
            $route.name === 'subscription-details',
        }">
        Subscriptions
      </nuxt-link>
    </li>

    <li>
      <button @click="logOut">Log Out</button>
    </li>
  </ul>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'AccountLinks',

    methods: {
      ...mapActions(['closeHeaderMenu']),

      logOut() {
        this.$store.dispatch('removeAccessToken')

        this.closeHeaderMenu()

        this.$liveChat.handleLogOut()

        const { domain } = this.$config[this.$store.state.locale]

        window.location = `https://checkout.${domain}/account/logout`
      },
    },
  }
</script>
