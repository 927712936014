<template>
  <span class="select select--auto-width">
    <label v-if="label" :for="id">{{ label }}</label>
    <select :id="id" v-model="currentOption" ref="visibleSelect">
      <option v-for="(name, value) in options" :key="value" :value="value" :selected="currentOption == value" @input="this.$emit('input', value)">{{ name }}</option>
    </select>
    <select class="accessible" ref="hiddenSelect">
      <option :value="currentOption">{{ options[currentOption] }}</option>
    </select>
  </span>
</template>

<script>
  export default {
    name: 'AutoWidthSelect',

    props: {
      id: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: null
      },
      options: {
        type: Object,
        default: null
      },
      default: {
        type: String,
        default: null
      }
    },

    data() {
      return {
        currentOption: null
      }
    },

    methods: {
      setWidth() {
        this.$nextTick(() => {
          var width = this.$refs.hiddenSelect.offsetWidth;

          this.$refs.visibleSelect.style.width = width + 'px';
        });
      }
    },

    watch: {
      currentOption(newVal) {
        this.$emit('input', newVal);

        this.setWidth();
      }
    },

    created() {
      var arr = Object.keys(this.options);

      this.currentOption = this.default ? this.default : arr[0];
    }
  };
</script>
