<template>
  <div class="rewards" :class="isAccount ? 'rewards--account' : null">
    <component :is="isAccount ? 'h3' : 'h2'">
      Rewards
    </component>
    <div class="row gutter-small">
      <div
        v-for="(reward, i) in rewards"
        :key="i"
        class="column small-12 large-6"
      >
        <RewardsCard
          :isAccount="isAccount"
          :reward="reward"
          :id="reward.id"
          :title="reward.reward.name"
          :numPoints="reward.points_price"
          @rewardRedeemed="rewardRedeemed(reward)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import RewardsCard from '@/components/global/RewardsCard'
  import LoadMoreButton from '@/components/global/LoadMoreButton'

  export default {
    name: 'RewardsList',

    components: {
      RewardsCard,
      LoadMoreButton,
    },

    props: {
      isAccount: {
        type: Boolean,
        default: false,
      },

      rewards: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        showLess: false,
      }
    },

    methods: {
      rewardRedeemed(reward) {
        this.$emit('rewardRedeemed')
      },
    },
  }
</script>
