<template>
  <section class="page-title" :class="{ 'page-title--alone': isAlone }">
    <div class="row">
      <div class="column">
        <template v-if="accessibleTitle">
          <h1 class="accessible">{{ accessibleTitle }}</h1>
          <span class="h1" v-html="title" />
        </template>

        <template v-else>
          <h1 v-html="title" />
        </template>

        <p v-if="text">{{ text }}</p>

        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'PageTitle',

    props: {
      title: {
        type: [String, Number],
        default: '',
      },
      accessibleTitle: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: null,
      },
      isAlone: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
