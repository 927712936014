<template>
  <div class="rewards__card">
    <div>
      <span class="h3">{{ reward.reward.name }}</span>
      <span>{{ reward.points_price }} Points</span>

      <span class="error" v-if="error">
        Something went wrong redeeming this reward. Please try again.
      </span>
    </div>

    <Button
      v-if="isAccount"
      text="Redeem"
      :disabled="disabled"
      @click="openModal(`reward-online-${reward.id}`)" />

    <Modal v-if="isAccount && !disabled" :type="`reward-online-${reward.id}`">
      <template v-slot:title>
        <template v-if="isConfirmed">
          <h2>{{ reward.reward.name }}</h2>
          <span>{{ reward.points_price }} Points</span>
        </template>
        <template v-else>
          <h2>Are you sure you want to redeem this reward?</h2>
        </template>
      </template>

      <template v-slot:body>
        <template v-if="isConfirmed">
          <template v-if="reward.product">
            <h3>{{ reward_code }}</h3>

            <p>
              We've added your complimentary {{ reward.reward.name }} to the
              cart. Make sure the discount code is applied when you're checking
              out.
            </p>
          </template>

          <template v-else>
            <h3>{{ reward_code }}</h3>
            <p>Use this promo code on your next order to add this reward.</p>
          </template>
        </template>

        <div v-else class="button-wrapper">
          <Button text="Yes" @click="confirm" />
          <Button text="No" @click="closeModal" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  import Button from '@/components/global/Button'
  import Modal from '@/components/global/Modal'

  export default {
    name: 'RewardsCard',

    components: {
      Button,
      Modal,
    },

    props: {
      isAccount: {
        type: Boolean,
        default: false,
      },
      reward: {
        type: Object,
        requred: true,
      },
    },

    data() {
      return {
        reward_code: null,
        isConfirmed: false,
        error: false,
      }
    },

    computed: {
      ...mapState({
        customer_id: state => state.smile?.id,
        points_balance: state => state.smile?.points_balance,
      }),

      disabled() {
        if (!this.points_balance) return true

        if (this.reward.points_price > this.points_balance) return true

        return false
      },
    },

    methods: {
      ...mapActions(['openModal', 'closeModal']),

      async confirm() {
        try {
          this.error = false
          await this.redeemPoints()

          if (this.reward.product) {
            const product = this.$smile.productForCart(this.reward.product)

            this.$store.dispatch('addItemToCart', product)
          }

          this.$shopify.applyDiscount(
            this.reward_code,
            this.$store.state.cart.checkout.id
          )

          this.isConfirmed = true
        } catch (error) {
          console.log(error)

          this.error = true
          this.closeModal()
        }
      },

      async redeemPoints() {
        const { code } = await this.$smile.purchasePointsProduct(
          this.customer_id,
          this.reward.id,
          this.reward.points_price
        )

        if (!code) {
          throw new Error('something went wrong')
        }

        this.reward_code = code
        this.$emit('rewardRedeemed')
      },
    },
  }
</script>

<style scoped>
  .error {
    color: red;
  }
</style>
