<template>
  <transition name="fade-static">
    <div v-if="showFeedback" :class="[`form__${this.submitState}`, isDark]">
      {{ submitMessage }}
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'FormFeedback',

    props: {
      showFeedback: {
        type: Boolean,
        default: false,
      },
      submitState: {
        type: String,
        default: 'error',
      },
      submitMessage: {
        type: String,
        default: 'There are errors in the form',
      },
      darkMode: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isDark: this.darkMode ? 'dark' : '',
      }
    },
  }
</script>
