<template>
  <div
    v-if="hasGees"
    class="gees"
    :style="{ width: totalWidthInt + 'px', height: imageSizeInt + 'px' }"
  >
    <PrismicLazyImage
      v-for="(gee, index) in computedGees"
      :key="index"
      :to="hasLinks ? `/beauty-edit/author/${gee._meta.uid}` : ''"
      :image="gee.featured_image"
      :width="imageSizeInt"
      :height="imageSizeInt"
      class="cover"
      :style="{ 'width': imageSizeInt + 'px', 'left': index * (imageSizeInt / 2) + 'px' }"
    />
  </div>
</template>

<script>
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';

  export default {
    name: 'Gees',

    components: {
      PrismicLazyImage
    },

    props: {
      gees: {
        type: Array
      },
      hasLinks: {
        type: Boolean,
        default: false
      },
      imageSize: {
        type: [Number, String],
        default: 32
      }
    },

    computed: {
      hasGees() {
        return this.gees && this.gees[0];
      },
      computedGees() {
        return this.gees.map(gee => gee.author ? gee.author : gee);
      },
      numGees() {
        return this.gees.length;
      },
      imageSizeInt() {
        return parseInt(this.imageSize);
      },
      totalWidthInt() {
        return this.imageSizeInt + ((this.numGees - 1) * (this.imageSizeInt * 0.5));
      }
    }
  }
</script>
