<template>
  <transition>
    <div v-if="isOpen && type == activeModal" class="modal" :class="['modal--' + type]">
      <div class="modal__overlay" @click="closeModal"></div>
      <dialog class="modal__content" :open="isOpen">
        <div class="modal__title">
          <slot name="title"></slot>
          <button type="button" class="modal__close" @click="closeModal">
            <SvgIcon name="close" title="Close" />
          </button>
        </div>
        <div class="modal__body">
          <slot name="body"></slot>
        </div>
      </dialog>
    </div>
  </transition>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'Modal',

    components: {
      SvgIcon
    },

    props: {
      type: {
        type: String,
        default: null
      }
    },

    computed: {
      ...mapState({
        isOpen: state => state.modal.isOpen,
        activeModal: state => state.modal.activeModal
      })
    },

    methods: {
      ...mapActions([
        'closeModal'
      ])
    },

    mounted() {
      document.addEventListener('keyup', (evt) => {
        if (evt.keyCode === 27) {
          this.closeModal();
        }
      });
    }
  }
</script>
